import { PropsWithChildren, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import logger from '../utils/logger';
import { GoalView, Lookup } from '@views';
import { ActivityLookupViewCreator } from '@viewCreators';

import './CreateGoalForm.scss';

export interface CreateGoalFormProps extends PropsWithChildren {
  formState: GoalView;
  onChange: (fieldName: string, value: any) => void;
  validationErrors: Record<string, string | null>;
}

export const CreateGoalForm: React.FC<CreateGoalFormProps> = ({
  formState,
  onChange,
  validationErrors,
}) => {
  const [activities, setActivities] = useState<Lookup[]>([]);

  useEffect(() => {
    const fetchActivities = async () => {
      const view = await new ActivityLookupViewCreator().createView();
      setActivities(view.activities);
    };
    fetchActivities().catch((err) => logger.error(err));
  }, []);

  return (
    <Box display="flex" flexDirection="column" sx={{ gap: 2 }}>
      <FormControl error={!!validationErrors.activityId}>
        <InputLabel id="activity">Activity</InputLabel>
        <Select
          label="Activity"
          name="activity"
          value={formState.activityId}
          onChange={(e) => onChange('activityId', e.target.value)}
        >
          <MenuItem value={-1}>Select an Activity</MenuItem>
          {activities.map((activity) => (
            <MenuItem key={activity.id} value={activity.id}>
              {activity.name}
            </MenuItem>
          ))}
        </Select>
        {validationErrors.activityId && (
          <FormHelperText>{validationErrors.activityId}</FormHelperText>
        )}
      </FormControl>

      <Grid container display={'flex'} flexDirection={'row'} gap={1}>
        {/* Frequency */}
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="frequency">Frequency</InputLabel>
          <div className="goal-select">
            <Select
              label="Frequency"
              name="frequency"
              value={formState.frequency}
              onChange={(e) => onChange('frequency', e.target.value)}
              error={!!validationErrors.frequency}
              sx={{ width: '100px' }}
            >
              {[...Array(7)].map((_, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
            <p>times per week</p>
          </div>
          {validationErrors.frequency && (
            <FormHelperText>{validationErrors.frequency}</FormHelperText>
          )}
        </FormControl>

        {/* Timeframe - Ex: per week */}
        {/* Hiding this as the user cannot interact with it. However, we need the value for the backend */}
        <FormControl sx={{ width: '79%', display: 'none' }}>
          <InputLabel id="timeframe">Timeframe</InputLabel>
          <Select
            label="Timeframe"
            name="timeframe"
            defaultValue={1}
            size={'medium'}
            variant={'outlined'}
            labelId={'timeframe'}
            value={formState.timeframe}
            onChange={(e) => onChange('timeframe', e.target.value)}
            readOnly
            className="select-readonly"
          >
            <MenuItem selected key={1} value={1}>
              {'Times Per Week'}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid container display={'flex'} flexDirection={'row'} gap={1}>
        {/* Duration */}
        <FormControl sx={{ width: '100%' }}>
          <div className="goal-select">
            <TextField
              name={'duration'}
              type="number"
              value={formState.duration}
              onChange={(e) => onChange('duration', Number(e.target.value))}
              error={!!validationErrors.duration}
              helperText={validationErrors.duration}
              label={'Duration'}
              onFocus={(e) => {
                e.target.select();
              }}
              sx={{ width: '100px' }}
            ></TextField>
            <p>minutes per session</p>
          </div>
        </FormControl>
        {/* Duration unit - Ex: minutes */}
        {/* Hiding this as the user cannot interact with it. However, we need the value for the backend */}
        <FormControl sx={{ width: '79%', display: 'none' }}>
          <InputLabel id="duration">Duration</InputLabel>
          <Select
            defaultValue={2}
            name={'durationTimeframe'}
            value={formState.durationTimeframe}
            label={'Duration'}
            size={'medium'}
            variant={'outlined'}
            labelId={'duration'}
            onChange={(e) => onChange('durationTimeframe', e.target.value)}
            readOnly
            className="select-readonly"
          >
            <MenuItem selected key={2} value={2}>
              {'Minutes'}
            </MenuItem>
          </Select>
          {validationErrors.durationTimeframe && (
            <FormHelperText>
              {validationErrors.durationTimeframe}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <p>
        This will be your goal from {formState.startDate.toFormat('MM/dd/yyyy')}{' '}
        to {formState.endDate.toFormat('MM/dd/yyyy')}
      </p>
      {/* Hiding the date picker as the user can't interact with it. However, we need the value for the backend */}
      <DatePicker
        label="Start Date"
        value={formState.startDate}
        onChange={(date) => onChange('startDate', date)}
        readOnly
        className="date-picker-readonly"
        sx={{ display: 'none' }}
      />
      {validationErrors.startDate && (
        <FormHelperText>{validationErrors.startDate}</FormHelperText>
      )}

      <DatePicker
        label="End Date"
        value={formState.endDate}
        onChange={(date) => onChange('endDate', date)}
        readOnly
        className="date-picker-readonly"
        sx={{ display: 'none' }}
      />
      {validationErrors.endDate && (
        <FormHelperText>{validationErrors.endDate}</FormHelperText>
      )}

      <TextField
        label="Notes to self about this activity (optional)"
        name="alternate"
        value={formState.alternate}
        onChange={(e) => onChange('alternate', e.target.value)}
        error={!!validationErrors.alternate}
        helperText={validationErrors.alternate}
        style={{ display: 'none' }} // Hiding this as it's taking space and isn't needed. However, we need the value for the backend currently. Will be redesigned in a couple months.
      />
    </Box>
  );
};
